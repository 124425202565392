<template>
  <div>
    <van-tabbar v-model="activeTab" :fixed="false">
      <van-tabbar-item icon="home-o">标签</van-tabbar-item>
      <van-tabbar-item icon="search">标签</van-tabbar-item>
      <van-tabbar-item icon="friends-o">标签</van-tabbar-item>
      <van-tabbar-item icon="setting-o">标签</van-tabbar-item>
    </van-tabbar>
    <el-card v-for="(item,index) in listShipment" :key="index" style="margin:10px 5px;" :body-style="{padding:'10px'}">
      <div style="display:flex;flex-direction: column;">
        <div style="display:flex;justify-content:space-between;">
          <div>{{item.contactName}}</div>
          <div>{{item.workflowGroupId}}</div>
        </div>
        <van-divider :style="{  margin: '0px' }"></van-divider>
        <div style="display:flex;height:100px;">
          <div style="display:flex;justify-content: center; align-items: center;">订单商品</div>
          <div style="flex:1;display:flex;justify-content: center; align-items: center;">{{item.detail}}</div>
        </div>
        
      </div>
    </el-card>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);

export default {
  data() {
    return { activeTab: "", listShipment: [] };
  },
  methods: {},
  mounted() {
    document.title = "我的出货单";
    let that = this;
    that.axios.post("/Shipment/GetMyListView").then(function (response) {
      that.listShipment = response.data.data;
    });
  },
};
</script>

<style scoped>
.myheader {
  flex-direction: column;
}
</style>
